import {
  parseAuthors,
  parseDate,
  superParser,
  createLink,
  arrayIncludesCaseInsensitive,
  removeUrls,
  urlRegex,
  stringWithoutEndings,
//  datasetMetadataFromId, // currenty unused; causes compile warning
  highlightText,
  extractPlainTextFromParsedLinks,
} from "../utilities/datasetManager";
import { Datasets, userImportedFileDatasetId } from "../components/Datasets";
import { 
  SEARCH_OPTION_WHOLE_WORD, 
  SEARCH_OPTION_NONE, 
  SEARCH_OPTION_IGNORE_URL,
  substringsIn, 
  isMatch, 
  isMatchMulti, 
  filterRecords 
} from "../utilities/searchManager";
//import { ThemeContext } from "@hig/theme-context"; // unused; causes compile warning

export { runUnitTests };

//////////////////////////////////////////////////////////////////////////

// This is the main unit test function. Get here by entering "test()"
// into the JavaScript console when the app is running.
//
// These functions To break into the debugger prior to executing any test, inser

function runUnitTests() {
  console.log("%cStarting runUnitTests...", "font-weight: bold");

  let failCount = 0; // running count of failed tests

  // We order our tests roughly from low-level to high-level.
  // Each routine tends to rely on the one(s) before it.

  // Test various utility routines.
  failCount += test_arrayIncludesCaseInsensitive();
  failCount += test_stringWithoutEndings();

  // These routines test the core of our search functionality.
  // They test our ability to parse a search string and identify matches.
  failCount += test_substringsIn(); // parse a search string into substrings
  failCount += test_isMatch(); // does a substring match a single field value?
  failCount += test_isMatchMulti(); // does a search string match multiple field values (i.e. a record)?
  failCount += test_Search(); // which dataset records does a search string match?
  failCount += test_fieldsToSearch();

  // Test parsing of free-text fields for special meanings.
  // These are good candidates for being replaced by AI.
  failCount += test_parseAuthors();
  failCount += test_parseDate();

  // Test our list of collections and datasets for integrity.
  failCount += test_Datasets();

  // Test our ability to identify and format URLs and found text.
  failCount += test_AutoLinking();
  failCount += test_FoundTextHighlighting();

  // Test our ability to remove URLs from text.
  failCount += test_MatchIgnoreUrls();

  console.log("%cFinished runUnitTests; %ctotal failures: " + failCount, fmt_bold, failCount? fmt_error : fmt_success);

} // end runUnitTests

//////////////////////////////////////////////////////////////////////////

const fmt_error = "color: red; font-weight: bold";
const fmt_warning = "color: brown";
const fmt_success = "color: green";
const fmt_bold = "font-weight: bold";

//////////////////////////////////////////////////////////////////////////

function logTestResults(passed, title, input, expected, actual, loose = false) {
  if (passed) {
    // console.log("%cPass: " + title, fmt_success);
    // console.log("  Input:", input);
    // console.log("  Expected:", expected);
    // console.log("  Actual:", actual);
  } else {
    if (loose) {
      // A "loose" test is one where we pass it even if it didn't get the expected
      // result. This is for non-critical failures (like author parsing) and must
      // be specified by each test for which it is OK. We log it as a warning so
      // we don't forget about it.
      logWarning("loose pass: " + title);
    } else {
      // normal failure: show complete info
      console.log("%cFail: " + title, fmt_error);
      console.log("  Input:", input);
      console.log("  Expected:", expected);
      console.log("  Actual:", actual);
      /*
    console.log({
      array1: JSON.stringify(actual),
      array2: JSON.stringify(expected)
    });
    */
    }
  }
} // end logTestResults

//////////////////////////////////////////////////////////////////////////

function logTestStart(testName)
{
  // Now that unit tests are pretty stable, there's no real need for
  // this in addition to logTestFinish. But we keep it around because
  // it can be nice when debugging.

  //console.log("%cStarting " + testName + "...", "font-weight: bold");
} // end logTestStart

//////////////////////////////////////////////////////////////////////////

function logTestFinish(testName, testCount, passCount)
{
  // Report counts of passes and fails.
  // Format any fails dramatically.
  let failCount = testCount - passCount;
  if (failCount) {
    console.log("%cFinished " + testName + "; %c" + failCount + "/" + testCount + " failed", fmt_bold, fmt_error);
  }
  else {
    console.log("%cFinished " + testName + "; %c" + passCount + "/" + testCount + " passed", fmt_bold, fmt_success);
  }
  return testCount - passCount; // return the failure count as a convenience

} // end logTestFinish

//////////////////////////////////////////////////////////////////////////

function logWarning(message) {
  console.log("%cWarning: " + message, fmt_warning);
} // end logWarning

//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////

// This needs commenting (and a unit test?).

function arraysAreEqual(array1, array2) {
  return JSON.stringify(array1) === JSON.stringify(array2);
} // end arraysAreEqual

//////////////////////////////////////////////////////////////////////////

// This needs commenting (and a unit test?).

function htmlLinksAreEqual(link1, link2) {
  return (
    link1.props !== undefined &&
    link2.props !== undefined &&
    link1.props.href !== undefined &&
    link2.props.href !== undefined &&
    link1.props.href === link2.props.href &&
    link1.props.target !== undefined &&
    link2.props.target !== undefined &&
    link1.props.target === link2.props.target &&
    link1.props.children !== undefined &&
    link2.props.children !== undefined &&
    (link1.props.children === link2.props.children ||
      (Array.isArray(link1.props.children) &&
        !Array.isArray(link2.props.children) &&
        link1.props.children.join("") === link2.props.children) ||
      (Array.isArray(link2.props.children) &&
        !Array.isArray(link1.props.children) &&
        link2.props.children.join("") === link1.props.children))
  );
} // end htmlLinksAreEqual

//////////////////////////////////////////////////////////////////////////

// This needs commenting (and a unit test?).

// https://stackoverflow.com/questions/4059147/check-if-a-variable-is-a-string-in-javascript
function safeIsString(value) {
  const valueType = Object.prototype.toString.call(value);
  // console.log('safeIsString', valueType, valueType == '[object String]');
  return valueType === "[object String]";
} // end safeIsString

//////////////////////////////////////////////////////////////////////////

// Test our case-insensitive alternative to array.includes().
// (This supports various dataset operations such as auto-bold
// and auto-italic that are specified via column headers.)

function test_arrayIncludesCaseInsensitive() {
  const testName = "test_arrayIncludesCaseInsensitive";
  logTestStart(testName);

  const tests = [
    {
      title: "Blank target string",
      arr: ["One", "Two", "Three"],
      str: "",
      expected: false,
    },
    {
      title: "Empty array",
      arr: [],
      str: "target",
      expected: false,
    },
    {
      title: "Empty array and blank target string",
      arr: [],
      str: "",
      expected: false,
    },
    {
      title: "Array of empty strings and blank target string",
      arr: [""],
      str: "",
      expected: true,
    },
    {
      title: "Empty input string",
      arr: ["One", "Two", "Three"],
      str: "",
      expected: false,
    },
    {
      title: "One match",
      arr: ["One", "Two", "Three"],
      str: "three",
      expected: true,
    },
    {
      title: "Two matches",
      arr: ["One", "Two", "Three"],
      str: "three",
      expected: true,
    },
    {
      title: "No matches",
      arr: ["One", "Two", "Three"],
      str: "four",
      expected: false,
    },
    {
      title: "One partial match",
      arr: ["One", "Two", "Three"],
      str: "EE",
      expected: false,
    },
  ];

  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger; // to invoke this, add "debug: true," to any test object

    const result = arrayIncludesCaseInsensitive(test.arr, test.str);
    const passed = result === test.expected;
    logTestResults(
      passed,
      testName + ": " + test.title,
      "[" + test.arr + "] contains '" + test.str + "'",
      test.expected,
      result
    );
    ++testCount;
    if (passed) ++passCount;
  });

return logTestFinish(testName, testCount, passCount);

} // end test_arrayIncludesCaseInsensitive

//////////////////////////////////////////////////////////////////////////

// Test our method for removing unwanted endings from strings.
// (This supports the "removeHeaderEndings" dataset capability.)

function test_stringWithoutEndings() {
  const testName = "test_stringWithoutEndings";
  logTestStart(testName);

  const tests = [
    {
      title: "Empty string should be unchanged.",
      input: "",
      expected: "",
    },
    {
      title:
        "String without any of the specified endings should not be changed.",
      input: "Expected",
      expected: "Expected",
    },
    {
      title: "Trailing parens should be removed",
      input: "Expected()",
      expected: "Expected",
    },
    {
      title: "Trailing parens and subsequently trailing '2' should be removed.",
      input: "Expected2()",
      expected: "Expected",
    },
    {
      title:
        "Trailing '2' should be removed, but not subsequently trailing parens, because order matters.",
      input: "Expected()2",
      expected: "Expected()",
    },
    {
      title: "Trailing asterisk should be removed.",
      input: "Expected*",
      expected: "Expected",
    },
    {
      title: "Trailing multi-word phrase should be removed.",
      input: "Expected (comma delimitated)",
      expected: "Expected",
    },
  ];

  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger;

    const result = stringWithoutEndings(
      ["()", "2", "*", " (comma delimitated)", ""],
      test.input
    );
    const passed = result === test.expected;
    logTestResults(
      passed,
      testName + ": " + test.title,
      test.input,
      test.expected,
      result
    );
    ++testCount;
    if (passed) ++passCount;
  });

return logTestFinish(testName, testCount, passCount);

} // end test_stringWithoutEndings

//////////////////////////////////////////////////////////////////////////

// Searching in Prism happens in these phases:
//
// 1) The search string is parsed into substrings, which are usually
// words delimited by whitespace. But they can also contain whitespace
// if enclosed between quote characters. This is tested by
// test_substringsIn.
//
// 2) Each substring is tested to see if it matches each field of
// each dataset record. (Matching is currently limited to testing
// if a substring occurs withn a data field string, but in future
// it could also happen via wildcarding, synonyms, stemming, etc.)
// We support a whole-word search option. In the future we might
// support other options such as starts-with, or case-sensitive.
// This is tested by test_isMatch.
//
// 3) If each substring matches at least one field in a record,
// then that record is deemed to have matched the search string.
// (Note that only one match per substring is needed, whereas in
// the subsequent display and highlighting phase, ALL matching
// portions must be identified). This is tested by test_isMatchMulti.
//
// 4) Portions of records that match the search string are identified
// for highlighting in the user interface. This can be complicated
// e.g. by matching portions of URLs that are truncated, or by multiple
// substrings matching the same part of a field. This is tested by
// test_FoundTextHighlighting.

//////////////////////////////////////////////////////////////////////////

// Test our ability to split a search string into substrings.
// Substrings are delimited by runs of whitespace, except that pairs
// of double-quote characters also define substrings that can contain
// multiple words as well as leading or trailing whitespace. Double-quote
// characters (whether paired or unpaired) separate substrings
// just as if they were whitespace.

function test_substringsIn() {
  const testName = "test_substringsIn";
  logTestStart(testName);

  const tests = [
    {
      title: `one word, no whitespace or quotes`,
      input: "cat",
      expected: ["cat"],
    },
    {
      title: `two words with space between`,
      input: "cat dog",
      expected: ["cat", "dog"],
    },
    {
      title: `run of white chars = same as single white char`,
      input: "cat     dog",
      expected: ["cat", "dog"],
    },
    {
      title: `two words, each quoted, no space between`,
      input: `"cat""dog"`,
      expected: ["cat", "dog"],
    },
    {
      title: `two substrings, each quoted, each with whitespace`,
      input: `"one " "two three"`,
      expected: ["one ", "two three"],
    },
    {
      title: `several substrings, with varying quotes and whitespace`,
      input: `"one " "two three"   four   " five six   "seven    `,
      expected: ["one ", "two three", "four", " five six   ", "seven"],
    },
    {
      title: `several substrings, with varying quotes and whitespace, unpaired`,
      input: `"one " "two three"   four   " five six   "seven  "  `,
      expected: ["one ", "two three", "four", " five six   ", "seven"],
    },
    {
      title: `unpaired quote at beginning = whitespace`,
      input: `"cat dog`,
      expected: ["cat", "dog"],
    },
    {
      title: `unpaired quote in mid string = whitespace`,
      input: `cat"dog`,
      expected: ["cat", "dog"],
    },
    {
      title: `unpaired quote at end = whitespace`,
      input: `cat dog"`,
      expected: ["cat", "dog"],
    },
    {
      title: `unpaired quote with whitespace earlier = whitespace`,
      input: `cat dog"bird`,
      expected: ["cat", "dog", "bird"],
    },
    {
      title: `unpaired quote with whitespace later = whitespace`,
      input: `cat"dog bird`,
      expected: ["cat", "dog", "bird"],
    },
    {
      title: `quoted word pair and unquoted third word`,
      input: `"cat dog" bird`,
      expected: ["cat dog", "bird"],
    },
    {
      title: `quoted word pair and quoted single word`,
      input: `"cat dog" "bird"`,
      expected: ["cat dog", "bird"],
    },
    {
      title: `leading quote and trailing space should be ignored`,
      input: `"cat `,
      expected: ["cat"],
    },
    {
      title: `quoted leading space should be retained`,
      input: `" cat"`,
      expected: [" cat"],
    },
    {
      title: `quoted leading spaces should be retained`,
      input: `"   cat"`,
      expected: ["   cat"],
    },
    {
      title: `quoted trailing space should be retained`,
      input: `"cat "`,
      expected: ["cat "],
    },
    {
      title: `quoted trailing spaces should be retained`,
      input: `"cat   "`,
      expected: ["cat   "],
    },
    {
      title: `two leading quote chars <-- first word "" has zero length and should be ignored`,
      input: `""cat`,
      expected: ["cat"],
    },
    {
      title: `three leading quote chars  <-- first word "" has zero length and should be ignored; unpaired quote = whitespace`,
      input: `"""cat`,
      expected: ["cat"],
    },
    {
      title: `two sets of paired quotes = no words`,
      input: `""""`,
      expected: [],
    },
    {
      title: `two sets of paired quotes with whitespace between = no words`,
      input: `"" ""`,
      expected: [],
    },
    {
      title: `empty string = no words`,
      input: "",
      expected: [],
    },
    {
      title: `single space = no words`,
      input: ` `,
      expected: [],
    },
    {
      title: `unpaired quote char = no words`,
      input: `"`,
      expected: [],
    },
    {
      title: `one unpaired quote char with trailing whitespace = no words`,
      input: `"   `,
      expected: [],
    },
    {
      title: `two quote chars = no words`,
      input: `""`,
      expected: [],
    },
    {
      title: `three quote chars = empty string plus whitespace = no words`,
      input: `"""`,
      expected: [],
    },
  ];

  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger;

    const result = substringsIn(test.input);
    const passed = arraysAreEqual(result, test.expected);
    logTestResults(
      passed,
      testName + ": " + test.title,
      test.input,
      test.expected,
      result
    );
    ++testCount;
    if (passed) ++passCount;
  });

return logTestFinish(testName, testCount, passCount);

} // end test_substringsIn

//////////////////////////////////////////////////////////////////////////

// This tests our ability to decide if a search substring (parsed
// from a raw search string by substringsIn) matches an individual
// data field string, exercising various search options.
//
// A match occurs when the substring is present in the data field string,
// subject to the search options in use.

function test_isMatch() {
  const testName = "test_isMatch";
  logTestStart(testName);

  // The "expected" parameter here is an array of expected outcomes.
  // The first item is the outcome for the default search option.
  // Subsequent items exercise other options and combinations.

  const tests = [
    // Cases with empty strings.
    {
      title: "search string = blank, data = blank",
      substring: "",
      dataField: "",
      expected: [true, true],
    },
    {
      title: "search string = not blank, data = blank",
      substring: "one",
      dataField: "",
      expected: [false, false],
    },
    {
      title: "search string = blank, data = not blank",
      substring: "",
      dataField: "one",
      expected: [true, true],
    },
    {
      title: "search string = not blank, data = not blank",
      substring: "one",
      dataField: "one two",
      expected: [true, true],
    },

    // Cases where the substring equals the data field string.
    {
      title: "search string = data (single word)",
      substring: "one",
      dataField: "one",
      expected: [true, true],
    },
    {
      title: "search string = data (single word + whitespace)",
      substring: "one ",
      dataField: "one ",
      expected: [true, true],
    },
    {
      title: "search string = data (whitespace + single word)",
      substring: " one",
      dataField: " one",
      expected: [true, true],
    },
    {
      title: "search string = data (whitespace + single word + whitespace)",
      substring: " one ",
      dataField: " one ",
      expected: [true, true],
    },
    {
      title: "search string = data (multi words)",
      substring: "one two",
      dataField: "one two",
      expected: [true, true],
    },

    // Typical cases; i.e. whole or partial words at various places in a multi-word string.
    {
      title: "whole word at start",
      substring: "one",
      dataField: "one two three",
      expected: [true, true],
    },
    {
      title: "whole word in middle",
      substring: "two",
      dataField: "one two three",
      expected: [true, true],
    },
    {
      title: "whole word at end",
      substring: "three",
      dataField: "one two three",
      expected: [true, true],
    },
    {
      title: "partial word at start",
      substring: "one",
      dataField: "onetwothree",
      expected: [true, false],
    },
    {
      title: "partial word in middle",
      substring: "two",
      dataField: "onetwothree",
      expected: [true, false],
    },
    {
      title: "partial word at end",
      substring: "three",
      dataField: "onetwothree",
      expected: [true, false],
    },
    {
      title: "multiple words with a partial word at start",
      substring: "on",
      dataField: "one two three",
      expected: [true, false],
    },
    {
      title: "multiple words with a partial word in middle",
      substring: "wo",
      dataField: "one two three",
      expected: [true, false],
    },
    {
      title: "multiple words with a partial word at end",
      substring: "ree",
      dataField: "one two three",
      expected: [true, false],
    },

    // Cases with whitespace.
    {
      title: "trailing whitespace",
      substring: "one ",
      dataField: "one two three",
      expected: [true, false],
    },
    {
      title: "trailing (extra) whitespace",
      substring: "one ",
      dataField: "one  two three",
      expected: [true, true],
    },
    {
      title: "surrounding whitespace",
      substring: " two ",
      dataField: "one two three",
      expected: [true, false],
    },
    {
      title: "surrounding (extra) whitespace",
      substring: " two ",
      dataField: "one  two  three",
      expected: [true, true],
    },
    {
      title: "leading whitespace",
      substring: " three",
      dataField: "one two three",
      expected: [true, false],
    },
    {
      title: "leading (extra) whitespace",
      substring: " three",
      dataField: "one two  three",
      expected: [true, true],
    },
    {
      title: "trailing run of whitespace",
      substring: "one    ",
      dataField: "one    two    three",
      expected: [true, false],
    },
    {
      title: "surrounding run of whitespace",
      substring: "    two    ",
      dataField: "one    two    three",
      expected: [true, false],
    },
    {
      title: "leading run of whitespace",
      substring: "    three",
      dataField: "one    two    three",
      expected: [true, false],
    },
    {
      title: "embedded whitespace",
      substring: "one two",
      dataField: "one two three",
      expected: [true, true],
    },
    {
      title: "embedded run of whitespace",
      substring: "two    three",
      dataField: "one    two    three",
      expected: [true, true],
    },

    // Failure cases.
    {
      title: "single word not in data string",
      substring: "1",
      dataField: "one two three",
      expected: [false, false],
    },
    {
      title: "multiple words not in data string",
      substring: "1 2",
      dataField: "one two three",
      expected: [false, false],
    },

    // Cases with multiple matches.
    {
      title: "multiple matches, first partial",
      substring: "play",
      dataField: "players play",
      expected: [true, true],
    },
    {
      title: "multiple matches, last partial",
      substring: "play",
      dataField: "play players",
      expected: [true, true],
    },
    {
      title: "multiple matches, all partial",
      substring: "play",
      dataField: "playersplay",
      expected: [true, false],
    },

    // Other cases that are interesting in some way.
    {
      title: "multi-word string plus another word inside brackets",
      substring: "one two {three",
      dataField: "one two {three four}",
      expected: [true, true],
    },
  ];

  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger;

    // Test default search option.
    let result = isMatch(test.dataField, test.substring, SEARCH_OPTION_NONE);
    let passed = result === test.expected[0];
    logTestResults(
      passed,
      testName + ": " + test.title + " (default)",
      "<" + test.substring + "> in <" + test.dataField + ">",
      test.expected[0],
      result
    );
    ++testCount;
    if (passed) ++passCount;

    // Test whole-word search option.
    result = isMatch(test.dataField, test.substring, SEARCH_OPTION_WHOLE_WORD);
    passed = result === test.expected[1];
    logTestResults(
      passed,
      testName + ": " + test.title + " (WW)",
      "<" + test.substring + "> in <" + test.dataField + ">",
      test.expected[1],
      result
    );
    ++testCount;
    if (passed) ++passCount;
  });

return logTestFinish(testName, testCount, passCount);

} // end test_isMatch

//////////////////////////////////////////////////////////////////////////

// Test our ability to determine if a raw search string, which can
// contain substrings, matches a set of field values, such as we might
// see in a database record, exercising relevant search options.
//
// A match occurs when every substring in the input string matches at
// least one data field string, subject to the search options in use.

function test_isMatchMulti() {
  const testName = "test_isMatchMulti";
  logTestStart(testName);

  // The "expected" parameter here is an array of expected outcomes.
  // The first item is the outcome for the default search option.
  // The second item is the outcome for the whole-word search option.

  const tests = [
    {
      title: "multiple whole words",
      searchString: "one two",
      dataFields: ["one", "two", "three"],
      expected: [true, true],
    },
    {
      title: "some whole words, some partial words",
      searchString: "one tw",
      dataFields: ["one", "two", "three"],
      expected: [true, false],
    },
    {
      title: "all partial words",
      searchString: "on tw",
      dataFields: ["one", "two", "three"],
      expected: [true, false],
    },
    {
      title: "no whole or partial words",
      searchString: "ona too",
      dataFields: ["one", "two", "three"],
      expected: [false, false],
    },

    // Cases with quotes in search string.
    {
      title: "quoted whitespace success",
      searchString: '"one " "two three"',
      dataFields: ["one ", "two three"],
      expected: [true, true],
    },
    {
      title: "quoted whitespace failure",
      searchString: '"one " "two three"',
      dataFields: ["one", "two", "three"],
      expected: [false, false],
    },
    {
      title: "search string = one double quote, empty data string",
      searchString: '"',
      dataFields: [""],
      expected: [true, true],
    },
    {
      title: "search string = one double quote, non-empty data string",
      searchString: '"',
      dataFields: ["one"],
      expected: [true, true],
    },
    {
      title: "quoted trailing whitespace",
      searchString: '"one "',
      dataFields: ["one"],
      expected: [false, false],
    },
    {
      title: "quoted embedded whitespace",
      searchString: '"two three"',
      dataFields: ["two"],
      expected: [false, false],
    },
    {
      title: "quoted leading whitespace",
      searchString: '" one"',
      dataFields: ["one"],
      expected: [false, false],
    },
  ];

  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger;

    // Test default search option.
    let result = isMatchMulti(
      test.dataFields,
      test.searchString,
      SEARCH_OPTION_NONE
    );
    let passed = result === test.expected[0];
    logTestResults(
      passed,
      testName + ": " + test.title + " (default)",
      "<" + test.searchString + "> in <" + test.dataFields + ">",
      test.expected[0],
      result
    );
    ++testCount;
    if (passed) ++passCount;

    // Test whole-word search option.
    result = isMatchMulti(
      test.dataFields,
      test.searchString,
      SEARCH_OPTION_WHOLE_WORD
    );
    passed = result === test.expected[1];
    logTestResults(
      passed,
      testName + ": " + test.title + " (WW)",
      "<" + test.searchString + "> in <" + test.dataFields + ">",
      test.expected[1],
      result
    );
    ++testCount;
    if (passed) ++passCount;
  });

return logTestFinish(testName, testCount, passCount);

} // end test_isMatchMulti

//////////////////////////////////////////////////////////////////////////

// These comments are from an old version of test_isMatch that had a lot
// of confusion and clutter. That has been cleaned up, but it might be
// worth checking these cases for any lingering failures.

// Found a couple edge cases; go to http://localhost:3000/?d=test_basic_loading and:
// search for " <- unpaired quote should be ignored; should see three records
// search for see" <-- unpaired quote should be ignored; should see one record

// These searches (all with WW=ON) have an unpaired quote, which should be ignored, and in all cases one record should be shown.
// http://localhost:3000/?d=test_basic_formatting&q=%22 <-- search for "

// http://localhost:3000/?d=test_basic_formatting&q=%22is <-- search for "is

// http://localhost:3000/?d=test_basic_formatting&q=is%22 <-- search for is"

// This search (with WW=OFF) shows 1 record (good)
// http://localhost:3000/?d=test_basic_formatting&q=%22is%20supported%22 <-- search for "is supported"

// The same search (with WW=ON) shows 0 records. It should show 1 record and highlight: is supported
// http://localhost:3000/?d=test_basic_formatting&q=%22is%20supported%22 <-- search for "is supported"

// Go here: http://localhost:3000/?d=test_basic_loading&q=see%20records
// the search phrase is "see records" -- you will see that 1 record matches (good)
// turn on whole word matching -- you will see that 0 records match (bad, because the same record should still match, since each whole word is present in the record)

// http://localhost:3000/?d=dxc&q=%22unified%20sit%22 <-- ...closing the quote makes it fail with 0 results

// http://localhost:3000/?d=dxc&q=%22me%20menu%22%20account <-- ...adding "account" makes it fail with 0 results

//////////////////////////////////////////////////////////////////////////

// Test searching at the highest level; i.e. our ability to determine
// the set of records in a dataset that match a raw search string,
// exercising relevant search options.
//
// A match occurs when every substring in the search string matches one
// or more fields of the record, subject to the search options in use.
//
// Note that FilterRecords is based on isMatchMulti, which is based on
// isMatch and substringsIn, and they all have fairly robust unit tests.
// So we don't have to reproduce every one of those test cases here, but
// we do want to have a good smattering of coverage.

function test_Search() {
  const testName = "test_Search";
  logTestStart(testName);

  // TBD: Find a way to compactly test more search option variations.
  
  // We re-use this dataset content object in many tests, for brevity.

  const content_names = {
    headers: ["First", "Last"],
    records: [
      ["Andre", "Paterlini"],
      ["Lars", "Jensen"],
    ],
  };

  const content_names_with_urls = {
    headers: ["First", "Last"],
    records: [
      ["Andre", "<https://wiki.autodesk.com/x/5GZMJw|[Paterlini]>"],
      ["=HYPERLINK('https://wiki.autodesk.com/display/edu/Learning+pathway+validation+research';'Lars')", "Jensen"],
    ],
  };

  const content_phrases = {
    headers: ["phrase"],
    records: [
      ["Today is the day"],
      ["for all good people"],
      ["to come to the aid"],
      ["of their party."],
    ],
  };

  // unused; causes compile warning
  // const content_3x3 = {
  //   headers: ["Hdr 0", "Hdr 1", "Hdr 2"],
  //   records: [
  //     ["Rec 0 Fld 0", "Rec 0 Fld 1", "Rec 0 Fld 2"],
  //     ["Rec 1 Fld 0", "Rec 1 Fld 1", "Rec 1 Fld 2"],
  //     ["Rec 2 Fld 0", "Rec 2 Fld 1", "Rec 2 Fld 2"],
  //   ],
  // };

  const tests = [
    {
      title: "1. Search string = empty",
      searchString: "",
      content: content_names,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [ // empty search should match everything
        ["Andre", "Paterlini"],
        ["Lars", "Jensen"],
      ],
    },
    {
      title: "2. Search string = whitespace",
      searchString: "   ",
      content: content_names,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [ // blank search should match everything
        ["Andre", "Paterlini"],
        ["Lars", "Jensen"],
      ],
    },
    {
      title: "3. Search string = unpaired double-quote char",
      searchString: '"', // unmatched quote = whitespace; same as empty string
      content: content_names,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        ["Andre", "Paterlini"],
        ["Lars", "Jensen"],
      ],
    },
    {
      title: "3b. Search string = text with unpaired double-quote char",
      searchString: ' lars"', // unmatched quote = whitespace
      content: content_names,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        ["Lars", "Jensen"],
      ],
    },
    {
      title: "4. Search string = single word",
      searchString: "andre",
      content: content_names,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        ["Andre", "Paterlini"],
      ],
    },
    {
      title: "5. Search string = blank (whole word)",
      searchString: "",
      content: content_names,
      searchOptions: SEARCH_OPTION_WHOLE_WORD,
      expected: [ // blank whole word should match everything
        ["Andre", "Paterlini"],
        ["Lars", "Jensen"],
      ],
    },
    {
      title: "6. Search string = partial match (whole word)",
      searchString: "dre",
      content: content_names,
      searchOptions: SEARCH_OPTION_WHOLE_WORD,
      expected: [],
    },
    {
      title: "7. Search string = match of multiple whole words (whole word)",
      searchString: "Andre Paterlini",
      content: content_names,
      searchOptions: SEARCH_OPTION_WHOLE_WORD,
      expected: [ // should match multiple whole words
        ["Andre", "Paterlini"],
      ],
    },

  // Various tests of partial vs whole word matching.

    {
      title: "8. Search string matches partial and whole words (default)",
      searchString: "the",
      content: content_phrases,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        ["Today is the day"], ["to come to the aid"], ["of their party."],
      ],
    },
    {
      title: "9. Search string matches partial and whole words (whole word)",
      searchString: "the",
      content: content_phrases,
      searchOptions: SEARCH_OPTION_WHOLE_WORD,
      expected: [
        ["Today is the day"], ["to come to the aid"],
      ],
    },
    {
      title: "10. Search string matches multiple whole words (default)",
      searchString: "to",
      content: content_phrases,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        ["Today is the day"], ["to come to the aid"],
      ],
    },
    {
      title: "11. Search string matches multiple whole words (whole word)",
      searchString: "to",
      content: content_phrases,
      searchOptions: SEARCH_OPTION_WHOLE_WORD,
      expected: [
        ["to come to the aid"],
      ],
    },
    {
      title: "12. Quoted search string matches whole word at end (default)",
      searchString: '"the day"',
      content: content_phrases,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        ["Today is the day"],
      ],
    },
    {
      title: "13. Quoted search string matches whole word at end (whole word)",
      searchString: '"the day"',
      content: content_phrases,
      searchOptions: SEARCH_OPTION_WHOLE_WORD,
      expected: [
        ["Today is the day"],
      ],
    },
    {
      title: "14. Search string has multiple matching words (default)",
      searchString: "the y",
      content: content_phrases,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        ["Today is the day"],
        ["of their party."],
      ],
    },
    {
      title: "15. Search string has multiple matching words (whole word)",
      searchString: "the y",
      content: content_phrases,
      searchOptions: SEARCH_OPTION_WHOLE_WORD,
      expected: [
      ],
    },
    {
      title: "16. Search for a keyword inclosed on URL (ignore url)",
      searchString: "paterlini",
      content: content_names_with_urls,
      searchOptions: SEARCH_OPTION_WHOLE_WORD | SEARCH_OPTION_IGNORE_URL,
      expected: [
        ["Andre", "<https://wiki.autodesk.com/x/5GZMJw|[Paterlini]>"],
      ],
    },
    {
      title: "17. Search for a keyword which is part of the URL (ignore url)",
      searchString: "5GZMJw",
      content: content_names_with_urls,
      searchOptions: SEARCH_OPTION_WHOLE_WORD | SEARCH_OPTION_IGNORE_URL,
      expected: [
      ],
    },
    {
      title: "18. Search for a keyword which is part of the URL (whole word)",
      searchString: "5GZMJw",
      content: content_names_with_urls,
      searchOptions: SEARCH_OPTION_WHOLE_WORD,
      expected: [
        ["Andre", "<https://wiki.autodesk.com/x/5GZMJw|[Paterlini]>"],
      ],
    },
  ];

  ///////////////////////////////////////////////////////////////////////////////////////

  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger;

    const result = filterRecords(
      test.metadata,
      test.content,
      test.searchString,
      test.searchOptions
    );

    let passed = arraysAreEqual(result, test.expected);
    logTestResults(
      passed,
      testName + ": " + test.title,
      test.searchString,
      test.expected,
      result
    );
    ++testCount;
    if (passed) ++passCount;
  });

return logTestFinish(testName, testCount, passCount);

} // end test_Search

    // TBD: These test cases within test_Search used to fail using the old
    // implementation of isMatch. Review these to make sure they are well
    // represented in the new test_Search.

    // Go here: http://localhost:3000/?d=test_basic_loading&q=see%20records
    // the search phrase is "see records" -- you will see that 1 record matches (good)
    // turn on whole word matching -- you will see that 0 records match (bad, because the same record should still match, since each whole word is present in the record)

    // These searches (all with WW=ON) have an unpaired quote, which should be ignored, and in all cases one record should be shown.
    // http://localhost:3000/?d=test_basic_formatting&q=%22 <-- search for "

    // http://localhost:3000/?d=test_basic_formatting&q=%22is <-- search for "is

    // http://localhost:3000/?d=test_basic_formatting&q=is%22 <-- search for is"

    // This search (with WW=OFF) shows 1 record (good)
    // http://localhost:3000/?d=test_basic_formatting&q=%22is%20supported%22 <-- search for "is supported"
    // The same search (with WW=ON) shows 0 records. It should show 1 record and highlight: is supported
    // http://localhost:3000/?d=test_basic_formatting&q=%22is%20supported%22 <-- search for "is supported"

    // Make sure these cases are tested at some point.

      //title: `Search = cat (WW): cat contains 1 word: cat`,
      //title: `Search = cat dog (WW): cat dog contains 2 words: cat dog`,
      //title: `Search = "cat     dog" (WW): cat     dog contains 2 words: cat dog   <-- run of white chars = whitespace`,
      //title: `Search = "cat dog" contains 1 word: cat dog`,
      //title: `Search = "cat""dog" contains 2 words: cat dog`,
      //title: `Search = "cat dog contains 2 words: cat dog  <-- unpaired quote = whitespace`,
      //title: `Search = cat"dog contains 2 words: cat dog <-- unpaired quote = whitespace`,
      //title: `Search = cat dog" contains 2 words: cat dog  <-- unpaired quote = whitespace`,
      //title: `Search = cat dog"bird contains 3 words: cat dog bird  <-- unpaired quote = whitespace`,
      //title: `Search = cat dog"bird contains 3 words: cat dog bird  <-- unpaired quote = whitespace`,
      //title: `Search = "cat dog" bird contains 2 words: cat dog bird`,
      //title: `Search = cat  contains 1 word: cat  <-- whitespace outside quotes is ignored`,
      //title: `Search = "cat " contains 1 word: cat   <-- whitespace within quotes is not ignored`,
      //title: `Search = " contains 0 words`,
      //title: `Search = "" contains 0 words`,
      //title: `Search = ""cat contains 1 word: cat <-- first word "" has zero characters and is ignored`,
      //title: `Search = """cat contains 1 word: cat  <-- first word "" has zero characters and is ignored; unpaired quote = whitespace`,
      //title: `Search = "" "" contains 0 words  <-- words with zero characters are ignored`,
      //title: `Search = contains 0 words  <-- words with zero characters are ignored`,

//////////////////////////////////////////////////////////////////////////

// // This is a truncated version of the old test_Search function. It
// // it is here to preserve the callback structure until I understand
// // it better and can decide how/whether to restore its functionality.

// function test_Search_old() {
//   const testName = "test_Search_old";
//   logTestStart(testName);

//   // Callback function to test if the WW search is matching the expected words
//   const getExpectedStringFromHighlight = (test, result) => {
//     if (result[0] === undefined) {
//       console.error(
//         "getExpectedStringFromHighlight() invalid result given",
//         result
//       );
//       return null;
//     }

//     const highlghtResult = highlightText(
//       result[0][1], // search subject
//       test.input,
//       test.searchOptions
//     );

//     // Check if we highlight anything
//     const didHighlight = highlghtResult.some(
//       (element) => element.props !== undefined
//     );
//     if (didHighlight === false) {
//       return "";
//     }

//     // Removes the React components so we can compare strings only
//     const matchResult = highlghtResult
//       .map((element) => {
//         const string = (element.props !== undefined
//           ? element.props.children
//           : element
//         ).trim();
//         return string !== "" ? string : null;
//       })
//       .filter((element) => element !== null);

//     console.log(
//       "getExpectedStringFromHighlight()",
//       matchResult,
//       highlghtResult,
//       { searchMe: result[0][1], searchString: test.input }
//     );

//     return matchResult.join(" ");
//   }; // end getExpectedStringFromHighlight (within test_Search_old)

//   const tests = [
//     {
//       title: "Search = blank (WW)",
//       searchString: "",
//       //datasetId: "test_basic_formatting",
//       data: [
//         {
//           Search: "cat",
//           Content: "cat",
//           Expected: "cat",
//           Description: "cat contains 1 word: cat",
//         },
//       ],
//       searchOptions: SEARCH_OPTION_WHOLE_WORD,
//       expected: [["cat", "cat", "cat", "cat contains 1 word: cat"]],
//     },
//     {
//       title: `Search = "cat     dog" (WW): cat     dog contains 2 words: cat dog   <-- run of white chars = whitespace`,
//       searchString: "cat     dog",
//       datasetId: "test_ww_search",
//       data: [
//         {
//           Search: "cat     dog",
//           Content: "cat     dog",
//           Expected: "cat dog",
//           Description:
//             "cat     dog contains 2 words: cat dog   <-- run of white chars = whitespace",
//         },
//       ],
//       searchOptions: SEARCH_OPTION_WHOLE_WORD,
//       resultCallback: getExpectedStringFromHighlight,
//       expected: "cat dog",
//     },
//   ];

//   let testCount = 0;
//   let passCount = 0;
//   tests.forEach((test) => {
//     if (test.debug) debugger;

//     let datasetMetadata = test.metadata;
//     if (test.datasetId) {
//       datasetMetadata = datasetMetadataFromId(test.datasetId);
//     }
//     let datasetContent = dataTableFromJson(test.data);
//     if (test.dataTable) {
//       datasetContent = test.dataTable;
//     }
//     const result = filterRecords(
//       datasetMetadata,
//       datasetContent,
//       test.searchString,
//       test.searchOptions
//     );

//     let passed = false;
//     let finalResult;
//     if (typeof test.resultCallback === "function") { // understand and debug this
//       finalResult =
//         result.length === 0 ? "" : test.resultCallback(test, result);
//       passed = finalResult === test.expected;
//     } else {
//       finalResult = result;
//       passed = arraysAreEqual(result, test.expected);
//     }
//     logTestResults(
//       passed,
//       testName + ": " + test.title,
//       test.searchString,
//       test.expected,
//       finalResult
//     );
//     ++testCount;
//     if (passed) ++passCount;
//   });

//   logTestFinish(testName, testCount, passCount);
// } // end test_Search_old

//////////////////////////////////////////////////////////////////////////

// Tests the functionality of the fieldsToSearch metadata property.
// We don't test a variety of search options, quotes, and other such
// nuances; we just focus on making sure the right set of fields gets
// tested. This is a fairly basic set of tests, but the fieldsToSearch
// code is straightforward and has not produced many bugs, so it's not
// a priority to expand this test suite at present.
//
// (We don't test fieldsToExclude here, because that is processed at
// dataset load time, not search time -- although we should probably
// unit test the load process itself at some point.)

function test_fieldsToSearch() {
  const testName = "test_fieldsToSearch";
  logTestStart(testName);

  const tests = [

    ///////////////////////////////////////////////////////////////////////////////////////

    {
      title: "fieldsToSearch: no fields specified (should get no matches)",
      searchString: "l",
      content: {
        headers: ["First", "Last"],
        records: [
          ["Andre", "Paterlini"],
          ["Lars", "Jensen"],
        ]
      },
      metadata: {
        fieldsToSearch: [],
      },
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
      ],
    },

    ///////////////////////////////////////////////////////////////////////////////////////

    {
      title: "fieldsToSearch: one field specified (should search only that one)",
      searchString: "l",
      content: {
        headers: ["First", "Last"],
        records: [
          ["Andre", "Paterlini"],
          ["Lars", "Jensen"],
        ]
      },
      metadata: {
        fieldsToSearch: ["First"],
      },
      expected: [
        ["Lars", "Jensen"],
      ],
    },

    ///////////////////////////////////////////////////////////////////////////////////////

    {
      title: "fieldsToSearch: multiple fields specified (should search all of them)",
      searchString: "l",
      content: {
        headers: ["First", "Last"],
        records: [
          ["Andre", "Paterlini"],
          ["Lars", "Jensen"],
        ]
      },
      metadata: {
        fieldsToSearch: ["Last", "First"],
      },
      expected: [
        ["Andre", "Paterlini"],
        ["Lars", "Jensen"],
      ],
    },

    ///////////////////////////////////////////////////////////////////////////////////////

    {
      title: "fieldsToSearch: empty field name (legit field name; should work)",
      searchString: "l",
      content: {
        headers: ["First", ""],
        records: [
          ["Andre", "Paterlini"],
          ["Lars", "Jensen"],
        ]
      },
      metadata: {
        fieldsToSearch: [""],
      },
      expected: [
        ["Andre", "Paterlini"],
      ],
    },

    ///////////////////////////////////////////////////////////////////////////////////////

    {
      title: "fieldsToSearch: field name = whitespace (legit field name; should work)",
      searchString: "l",
      content: {
        headers: ["First", " "],
        records: [
          ["Andre", "Paterlini"],
          ["Lars", "Jensen"],
        ]
      },
      metadata: {
        fieldsToSearch: [" "],
      },
      expected: [
        ["Andre", "Paterlini"],
      ],
    },

    ///////////////////////////////////////////////////////////////////////////////////////

    {
      title: "fieldsToSearch: field does not occur (should get no matches)",
      searchString: "l",
      content: {
        headers: ["First", "Last"],
        records: [
          ["Andre", "Paterlini"],
          ["Lars", "Jensen"],
        ]
      },
      metadata: {
        fieldsToSearch: ["Middle"],
      },
      expected: [
      ],
    },

    ///////////////////////////////////////////////////////////////////////////////////////

    {
      title: "fieldsToSearch: field occurs multiple times (should search all of them)",
      searchString: "dre",
      content: {
        headers: ["First", "Last", "First"],
        records: [
          ["Andre", "Paterlini", "Karl"],
          ["Lars", "Jensen", "Drew"],
        ]
      },
      metadata: {
        fieldsToSearch: ["First"],
      },
      expected: [
        ["Andre", "Paterlini", "Karl"],
        ["Lars", "Jensen", "Drew"],
      ],
    },
  ];

  ///////////////////////////////////////////////////////////////////////////////////////

  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger;

    const result = filterRecords(
      test.metadata,
      test.content,
      test.searchString,
    );

    let passed = arraysAreEqual(result, test.expected);
    logTestResults(
      passed,
      testName + ": " + test.title,
      test.searchString,
      test.expected,
      result
    );
    ++testCount;
    if (passed) ++passCount;
  });

return logTestFinish(testName, testCount, passCount);

} // end test_fieldsToSearch

//////////////////////////////////////////////////////////////////////////

function test_MatchIgnoreUrls() {
  const testName = 'testMatchIgnoreUrls';

  const tests = [
    {
      title: "Content without URL",
      input: "The ACS UX Research team has been conducting pilot studies ...",
      cleanUpExpected: false,
    },
    {
      title: "Content with WIKI Url",
      input: '"=HYPERLINK(""https://wiki.autodesk.com/display/ACSUXR/AEC+Data+Model+with+ACC+-+Strategic+Research"";""AEC Data Model with ACC - Strategic Research"")"',
      cleanUpExpected: true,
    },
    {
      title: "Content with Excel/ALEX Url",
      input: '<https://wiki.autodesk.com/pages/viewpage.action?pageId=659318499|Autodesk Construction Cloud> <https://api.wiki.autodesk.com/x/5GZMJw|:edit:>',
      cleanUpExpected: true,
    },
  ];

  const matchUrlRegEx = new RegExp(urlRegex, 'gi');
  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger;

    const result = removeUrls(test.input);
    const passed = test.cleanUpExpected ? result !== test.input && matchUrlRegEx.test(result) === false : result === test.input;

    ++testCount;
    
    if (passed) ++passCount;
    
    logTestResults(
      passed,
      testName + ": " + test.title,
      test.input,
      test.expected,
      result
    );
  });

  return logTestFinish(testName, testCount, passCount);
}

// Test our ability to detect author names within 'Author' fields.
// Most of these test cases are taken from real-world datasets. A few of
// them fail, which we can live with for now, and improve this over time.

function test_parseAuthors() {
  const testName = "test_parseAuthors";
  logTestStart(testName);

  const tests = [
    {
      title: "Author = blank",
      input: null,
      expected: [],
    },
    {
      title: "Author = whitespace",
      input: " ",
      expected: [],
    },
    // https://wiki.autodesk.com/pages/viewpage.action?spaceKey=~jensenl&title=Prism+Use+Cases+-+Authors
    // This should still return the first name cus imageResolution() will still not find the corresponding avatar for Satwik
    {
      title: "Author = one word",
      input: "Satwik",
      expected: ["Satwik"],
    },
    {
      title: "Author = two words",
      input: "Michael Saunders",
      expected: ["Michael Saunders"],
    },
    {
      title: "Author = three words",
      input: "Andrea Fajardo Valerio",
      expected: ["Andrea Fajardo Valerio"],
    },
    {
      title: "Author = hyphenated last name",
      input: "Angel Huntington-Ortega",
      expected: ["Angel Huntington-Ortega"],
    },
    {
      title: "Author = comma-separated list",
      input: "Lars Jensen, Adam Richardson",
      expected: ["Lars Jensen", "Adam Richardson"],
    },
    {
      title: "Author = space-separated list of linked names",
      input:
        '<a href=""https://wiki.autodesk.com/display/~perchoj"" rel=""nofollow"">Jennifer Perchonok</a> <a href=""https://wiki.autodesk.com/display/~lockwoe"" rel=""nofollow"">Elise Lockwood</a> <a href=""https://wiki.autodesk.com/display/~maxfiep"" rel=""nofollow"">Peter Maxfield</a>',
      expected: ["Jennifer Perchonok", "Elise Lockwood", "Peter Maxfield"],
    },
    {
      title: "Author = comma-separated list of linked names",
      input:
        '<a href=""https://wiki.autodesk.com/display/~perchoj"" rel=""nofollow"">Jennifer Perchonok</a>,<a href=""https://wiki.autodesk.com/display/~lockwoe"" rel=""nofollow"">Elise Lockwood</a>,<a href=""https://wiki.autodesk.com/display/~maxfiep"" rel=""nofollow"">Peter Maxfield</a>',
      expected: ["Jennifer Perchonok", "Elise Lockwood", "Peter Maxfield"],
    },
    {
      title: "Author = comma+space-separated list of linked names",
      input:
        '<a href=""https://wiki.autodesk.com/display/~perchoj"" rel=""nofollow"">Jennifer Perchonok</a>, <a href=""https://wiki.autodesk.com/display/~lockwoe"" rel=""nofollow"">Elise Lockwood</a>, <a href=""https://wiki.autodesk.com/display/~maxfiep"" rel=""nofollow"">Peter Maxfield</a>',
      expected: ["Jennifer Perchonok", "Elise Lockwood", "Peter Maxfield"],
    },
    {
      title: "no delimiter, plain text (not sure how this even works)",
      input: "Emily Tsai Sara Kremer",
      expected: ["Emily Tsai"],
    },
    {
      title: "semicolon delimiter",
      input: "Michael Saunders; Ben Leduc-Mills",
      expected: ["Michael Saunders", "Ben Leduc-Mills"],
    },
    {
      title: "names with special meanings",
      input: "N/A",
      expected: [],
    },
    {
      title: "some names in parens, with other stuff",
      input: "Kyle Shulman (Lisa Seaman Consult)",
      expected: ["Kyle Shulman", "Lisa Seaman"],
    },
    {
      title: "aliases",
      input: "Holly Redahan OR Holly Matthews",
      expected: ["Holly Redahan", "Holly Matthews"],
    },
    {
      title: 'more aliases, no "starts with" match',
      input: "Yedige Tlegenov OR Yedige Tlegenov",
      expected: ["Yedige Tlegenov", "Yedige Tlegenov"],
    },
    {
      title: "more aliases, punctuation",
      input: "Nicole E Carey OR Nicole E. Carey OR Nic Carey",
      expected: ["Nicole E Carey", "Nicole E. Carey", "Nic Carey"],
    },
    {
      title: "other stuff, but no parens",
      input: "Gabe Zentall, Lamin Mansaray consulting",
      expected: ["Gabe Zentall", "Lamin Mansaray"],
    },
    {
      title: "commentary in the name field",
      input: "Christie McAllister, on behalf of Chrissy Charlton/ BMP team",
      expected: ["Christie McAllister", "Chrissy Charlton"],
      loose: true, // we can live with a failure here
    },
    {
      title: "commentary in the name field, with parens",
      input: "AEX, led by Prophet (Bre Arder Consulting)",
      expected: ["AEX", "Prophet", "Bre Arder"],
    },
    {
      title: "names of third-party companies	",
      input: "Emerge Interactive, Christie McAllister",
      expected: ["Emerge Interactive", "Christie McAllister"],
    },
    {
      title: "names of third-party companies, with parens",
      input:
        "Christie McAllister, led by Adam Richardson and Shane Chase (Enigma Bureau)",
      expected: [
        "Christie McAllister",
        "Adam Richardson",
        "Shane Chase",
        "Enigma Bureau",
      ],
    },
    {
      title: "comma and other stuff",
      input: "Lisa Seaman, as part of Gateways POD",
      expected: ["Lisa Seaman", "Gateways POD"],
      loose: true, // we can live with a failure here
    },
    {
      title: "name in parens (with delimiter too)",
      input: "Joe Kappes, Emily Tsai, (Alix Cohen)",
      expected: ["Joe Kappes", "Emily Tsai", "Alix Cohen"],
    },
    {
      title: 'not always "consulting"',
      input: "Judy Bayne, Lars Jensen assisting",
      expected: ["Judy Bayne", "Lars Jensen"],
    },
    {
      title: "person (company), person",
      input: "Adam Richardson (Enigma Bureau), Christie McAllister",
      expected: ["Adam Richardson", "Enigma Bureau", "Christie McAllister"],
    },
    {
      title: "all kinds of stuff",
      input:
        "Christie McAllister documenting, Diane Li/ BMP team commissioned PwC",
      expected: ["Christie McAllister", "Diane Li"],
      loose: true, // we can live with a failure here
    },
  ];

  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger;

    const result = parseAuthors([{ entry: test.input }]);
    const passed = arraysAreEqual(result, test.expected);
    logTestResults(
      passed,
      testName + ": " + test.title,
      test.input,
      test.expected,
      result,
      test.loose,
    );
    ++testCount;
    if (passed || test.loose) ++passCount;
  });

return logTestFinish(testName, testCount, passCount);

} // end test_parseAuthors

//////////////////////////////////////////////////////////////////////////

// Test our ability to evaluate 'Date' fields as meaningful dates.
// Several of these test cases are taken from real-world datasets.
// Date formatting is highly variable in the real world, but also
// potentially very useful, so worth some effort to improve over time.

function test_parseDate() {
  const testName = "test_parseDate";
  logTestStart(testName);

  const tests = [
    {
      title: "Date = blank",
      input: "",
      expected: "0000-00-00",
    },
    {
      title: "Date = random stuff",
      input: "NA",
      expected: "0000-00-00",
    },
    {
      title: "Date = 2022_07_04",
      input: "2022_07_04",
      expected: "2022-07-04",
    },
    {
      title: "Date = July 4, 2022",
      input: "July 4, 2022",
      expected: "2022-07-04",
    },
    {
      title: "Date = Jul 2022",
      input: "Jul 2022",
      expected: "2022-07-01",
    },
    {
      title: "Date = 2022",
      input: "2022",
      expected: "2022-01-01",
    },
    {
      title: "Date = Summer 2022",
      input: "Summer 2022",
      expected: "2022-06-01",
    },
    {
      title: "Date = FY22 Q3",
      input: "FY22 Q3",
      expected: "2022-08-01",
    },
    {
      title: "Date = 09/22/2022",
      input: "09/22/2022",
      expected: "2022-09-22",
    },
  ];

  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger;

    const result = parseDate(test.input);
    const passed = result === test.expected;
    logTestResults(
      passed,
      testName + ": " + test.title,
      test.input,
      test.expected,
      result
    );
    ++testCount;
    if (passed) ++passCount;
  });

return logTestFinish(testName, testCount, passCount);

} // end test_parseDate

//////////////////////////////////////////////////////////////////////////

// Test our global Datasets object. Flag a warning/error if there is
// a repeated dataset ID or collection ID, or invalid dataFile fields.

function test_Datasets() {
  const testName = "test_Datasets";
  logTestStart(testName);
  let testCount = 0;
  let passCount = 0;

  // Check for collection duplicate ids
  const collectionIdsArray = [];
  Object.entries(Datasets).forEach((entryArray) => {
    // const key = entryArray[0]; // unused; causes compile warning
    const collection = entryArray[1];
    collectionIdsArray.push(collection.id);
  });
  const collectionUniqueIdsArray = Array.from(new Set(collectionIdsArray));
  let passed = arraysAreEqual(collectionUniqueIdsArray, collectionIdsArray);
  logTestResults(
    passed,
    testName + ": check for repeated collection IDs",
    "UniqueCollectionIds",
    collectionUniqueIdsArray,
    collectionIdsArray
  );
  ++testCount;
  if (passed) ++passCount;

  // Checks if the dataset categories are valid
  Object.entries(Datasets).forEach((entryArray) => {
    const categoryKey = entryArray[0];
    const category = entryArray[1];
    // Check collection IDs
    passed = category.id !== undefined && category.id;
    ++testCount;
    if (passed) ++passCount;
    logTestResults(
      passed,
      testName + `: Collection "${categoryKey}"; check for collection ID`,
      categoryKey,
      category.id,
      category.id
    );
    // Check for collection datasets duplicate IDs.
    const datasetIdsArray = Datasets[categoryKey].datasets.map(
      (dataset) => dataset.id
    );
    const datasetUniqueIdsArray = Array.from(new Set(datasetIdsArray));
    passed = arraysAreEqual(collectionUniqueIdsArray, collectionIdsArray);
    ++testCount;
    if (passed) ++passCount;
      logTestResults(
      passed,
      testName +
        ": " +
        `collection "${categoryKey}"; check for repeated dataset IDs`,
      categoryKey,
      datasetUniqueIdsArray,
      datasetIdsArray
    );
    // Check for collection datasets invalid dataFile
    const datasetInvalidDataFileArray = [];
    Datasets[categoryKey].datasets.forEach((dataset) => {
      if (
        dataset.id !== userImportedFileDatasetId && // Skip user-uploaded entry since that will be null
        (dataset.dataFile === undefined || !dataset.dataFile)
      ) {
        datasetInvalidDataFileArray.push(dataset.id);
      }
    });
    passed = datasetInvalidDataFileArray.length === 0;
    ++testCount;
    if (passed) ++passCount;
    logTestResults(
      datasetInvalidDataFileArray.length === 0,
      testName +
        ": " +
        `Collection "${categoryKey}"; check for invalid dataset dataFile`,
      categoryKey,
      [],
      datasetInvalidDataFileArray
    );
  });

return logTestFinish(testName, testCount, passCount);

} // end test_Datasets

//////////////////////////////////////////////////////////////////////////

// Test detection and HTML tagging of hyperlinks, as described here:
// https://wiki.autodesk.com/display/PRISM/Prism+-+Standard+Formatting+and+Linking
// TBD: see whether we should eliminate the linking from the manual test datasets now that we have this

function test_AutoLinking() {
  const testName = "test_AutoLinking";
  logTestStart(testName);

  const tests = [
    {
      title: "1. One raw link",
      input: "http://www.apple.com",
      expected: [createLink(["http://www.apple.com", "http:/..."])],
    },
    {
      title: "2. One Excel-style link (semicolon separator)",
      input: '=HYPERLINK(""http://www.apple.com"";""Apple"")',
      expected: [createLink(["http://www.apple.com", "Apple"])],
    },
    {
      title: "3. One Excel-style link (comma separator)",
      input: '=HYPERLINK(""http://www.apple.com"",""Apple"")',
      expected: [createLink(["http://www.apple.com", "Apple"])],
    },
    {
      title: "4. One Excel-style link (semicolon separator with whitespace)",
      input: '=HYPERLINK(""http://www.apple.com"" ;  ""Apple"")',
      expected: [createLink(["http://www.apple.com", "Apple"])],
    },
    {
      title: "5. One Excel-style link (comma separator with whitespace)",
      input: '=HYPERLINK(""http://www.apple.com""  ,""Apple"")',
      expected: [createLink(["http://www.apple.com", "Apple"])],
    },
    {
      title: "6. One link surrounded by parens (as in OCTO)",
      input: "(http://www.apple.com)",
      expected: ["(", createLink(["http://www.apple.com", "http:/..."]), ")"],
    },
    {
      title: "7. One Slack-style link",
      input: "<http://www.apple.com|Apple>",
      expected: [createLink(["http://www.apple.com", "Apple"])],
    },
    {
      title: "8. One raw link with surrounding text",
      input: "Here is a link to the http://www.apple.com web site",
      expected: [
        "Here is a link to the ",
        createLink(["http://www.apple.com", "http:/..."]),
        " web site",
      ],
    },
    {
      title: "9. One Excel-style link with surrounding text",
      input:
        'Here is a link to the =HYPERLINK(""http://www.apple.com"";""Apple"") web site',
      expected: [
        "Here is a link to the ",
        createLink(["http://www.apple.com", "Apple"]),
        " web site",
      ],
    },
    {
      title: "10. One Slack-style link with surrounding text",
      input: "Here is a link to the <http://www.apple.com|Apple> web site",
      expected: [
        "Here is a link to the ",
        createLink(["http://www.apple.com", "Apple"]),
        " web site",
      ],
    },
    {
      title: "11. Two Slack-style links",
      input: "<http://apple.com|Apple> <http://microsoft.com|Microsoft>",
      expected: [
        createLink(["http://apple.com", "Apple"]),
        " ",
        createLink(["http://microsoft.com", "Microsoft"]),
      ],
    },
    {
      title: "12. Two Excel-style links",
      input:
        '=HYPERLINK(""http://apple.com"";""Apple"") =HYPERLINK(""http://microsoft.com"";""Microsoft"")',
      expected: [
        createLink(["http://apple.com", "Apple"]),
        " ",
        createLink(["http://microsoft.com", "Microsoft"]),
      ],
    },
    {
      title: "13. One Excel-style link and one Slack-style link",
      input:
        '=HYPERLINK(""http://www.apple.com"";""Apple"") <http://microsoft.com|Microsoft>',
      expected: [
        createLink(["http://www.apple.com", "Apple"]),
        " ",
        createLink(["http://microsoft.com", "Microsoft"]),
      ],
    },
    {
      title: "14. One Slack-style link and one raw link",
      input: "<http://apple.com|Apple> http://microsoft.com",
      expected: [
        createLink(["http://apple.com", "Apple"]),
        " ",
        createLink(["http://microsoft.com", "http:/..."]),
      ],
    },
    {
      title:
        "15. One Slack-style link with empty text; should see no field value",
      input: "<http://apple.com|>",
      expected: [createLink(["http://apple.com", ""])],
    },
    {
      title:
        "16. One Slack-style link with empty URL; should see linked text with blank target",
      input: "<|Apple>",
      expected: ["Apple"],
    },
    {
      title:
        "17. One Slack-style link with empty URL and empty text; should see no field value",
      input: "<|>",
      expected: [""],
    },
    {
      title:
        "18. One Excel-style link with empty URL; should see linked text with blank target",
      input: '=HYPERLINK("""";""Apple"")',
      expected: ["Apple"],
    },
    {
      title:
        "19. One Excel-style link with empty text; should see no field value",
      input: '=HYPERLINK(""http://www.apple.com"";"""")',
      expected: [createLink(["http://www.apple.com", ""])],
    },
    {
      title:
        "20. One Excel-style link with empty URL and empty text; should see no field value",
      input: '=HYPERLINK("""";"""")',
      expected: [""],
    },
    {
      title:
        "21. One Excel-style link with DXC-XD URL and text with break lines; TBD",
      input: `=HYPERLINK(""https://userpilot.com/blog/jobs-to-be-done-survey/"";""What is the reach segment's view of Autodesk as a trusted partner?
What are the needs of Reach in a Self-service manner?
Where are our current experiences falling short?
When would they want to come to Autodesk for support?
Why, if at all, do they grow their relationship with Autodesk?
What's their interest in community?
What are their jobs to be done?
Follow-up with broader JTBD survey - 1, 2, 3
What is their day-to-day like?
Who's in their company?
Are they even in a company, or just hobbyists?
More as we deem relevant
This project is not reliant upon 10 good minutes, but it is connected."")`,
      expected: [
        createLink([
          "https://userpilot.com/blog/jobs-to-be-done-survey/", 
          `What is the reach segment's view of Autodesk as a trusted partner?
What are the needs of Reach in a Self-service manner?
Where are our current experiences falling short?
When would they want to come to Autodesk for support?
Why, if at all, do they grow their relationship with Autodesk?
What's their interest in community?
What are their jobs to be done?
Follow-up with broader JTBD survey - 1, 2, 3
What is their day-to-day like?
Who's in their company?
Are they even in a company, or just hobbyists?
More as we deem relevant
This project is not reliant upon 10 good minutes, but it is connected.`
        ]),
      ],
    },
    {
      title:
        "21. One Excel-style link with DXC-XD URL and text with break lines; TBD",
      input: `=HYPERLINK(""https://wiki.autodesk.com/display/RES/REACH+Customers+-+Summary+of+Themes"";""See child page: REACH Customers - Summary of Themes for full details.
      Emerging themes:
      Reach Customer Characteristics and Needs
      Reach Customer Segmentation Data
      Price Sensitivity and VSBs
      VSB Onboarding
      Evaluation (Including Trialing) and VSBs
      Content That Drives Conversions With VSBs and SMBs
      Self-Service is Self-Serving. Ensuring High Customer Impact through Low Difficult Company Efforts
      Flexible Licensing To Extend Usage and Value Over a Lifetime with Autodesk (Operationalizing Customer Lifetime Value)"")`,
      expected: [
        createLink([
          "https://wiki.autodesk.com/display/RES/REACH+Customers+-+Summary+of+Themes",
          `See child page: REACH Customers - Summary of Themes for full details.
      Emerging themes:
      Reach Customer Characteristics and Needs
      Reach Customer Segmentation Data
      Price Sensitivity and VSBs
      VSB Onboarding
      Evaluation (Including Trialing) and VSBs
      Content That Drives Conversions With VSBs and SMBs
      Self-Service is Self-Serving. Ensuring High Customer Impact through Low Difficult Company Efforts
      Flexible Licensing To Extend Usage and Value Over a Lifetime with Autodesk (Operationalizing Customer Lifetime Value)`
        ])
      ],
    },
  ];

  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger;

    const results = superParser(test.input).filter((element) => element !== "");
    let passed = true;
    for (let currentIndex = 0; currentIndex < results.length; currentIndex++) {
      if (
        results[currentIndex] === undefined ||
        test.expected[currentIndex] === undefined
      ) {
        // console.log(`expected array element doesn't exist at index ${currentIndex}`);
        passed = false;
        break;
      }
      if (
        safeIsString(results[currentIndex]) &&
        safeIsString(test.expected[currentIndex])
      ) {
        // console.log('both are strings ...');
        if (results[currentIndex] !== test.expected[currentIndex]) {
          // console.log(`strings don't match at index ${currentIndex}`);
          passed = false;
          break;
        }
      } else if (
        !htmlLinksAreEqual(results[currentIndex], test.expected[currentIndex])
      ) {
        // console.log(`links don't match at index ${currentIndex}`);
        passed = false;
        break;
      }
    }
    ++testCount;
    if (passed) ++passCount;
    logTestResults(
      passed,
      testName + ": " + test.title,
      test.input,
      test.expected,
      results
    );
  });

return logTestFinish(testName, testCount, passCount);

} // end test_AutoLinking

//////////////////////////////////////////////////////////////////////////

// Test the highlighting of text that is found during a search operation.
// TBD: review all these test cases to see if they make sense.

function test_FoundTextHighlighting() {
  const testName = "test_FoundTextHighlighting";
  logTestStart(testName);

  const tests = [
    // This search (with WW=OFF) highlights is, but it should highlight is support
    // http://localhost:3000/?d=test_basic_formatting&q=%22is%20support%22 <-- search for "is support"
    {
      title: 'Search = "is support"',
      input: '"is support"',
      data: "Standard formatting is supported:",
      expected: ["Standard formatting is supported:"],
      searchOptions: SEARCH_OPTION_NONE,
    },
    // This search (with WW=OFF) shows 1 record (good) but it highlights is supported as separate words.
    // Since they are quoted, it should highlight them as a single "word": is supported
    // http://localhost:3000/?d=test_basic_formatting&q=%22is%20supported%22 <-- search for "is supported"
    {
      title: 'Search = "is supported"',
      input: '"is supported"',
      data: "Standard formatting is supported:",
      expected: ["Standard formatting ", "is supported", ":"],
      searchOptions: SEARCH_OPTION_NONE,
    },
    // No quotes (make sure those still work)
    {
      title: "Search = is support",
      input: "is support",
      data: "Standard formatting is supported:",
      expected: ["Standard formatting ", "is", " ", "support", "ed:"],
      searchOptions: SEARCH_OPTION_NONE,
    },
    {
      title: "Search = is supported",
      data: "Standard formatting is supported:",
      input: "is supported",
      expected: ["Standard formatting ", "is", " ", "supported", ":"],
      searchOptions: SEARCH_OPTION_NONE,
    },
    {
      title: 'Search = "',
      input: '"',
      data:
        "15. One Slack-style link with empty text; should see no field value",
      searchOptions: SEARCH_OPTION_NONE,
      expected:
        "15. One Slack-style link with empty text; should see no field value",
    },
    {
      title: 'Search = " (WW)',
      input: '"',
      data:
        "15. One Slack-style link with empty text; should see no field value",
      searchOptions: SEARCH_OPTION_WHOLE_WORD,
      expected:
        "15. One Slack-style link with empty text; should see no field value",
    },
    {
      title: "Search = format (WW)",
      input: "format",
      data:
        "Standard formatting is supported:\n- All fields called 'Name' or 'Title' (case-insensitive match) should be bold.\n- All fields called 'Name' (case-insensitive match) should be italicized.\n- Fields called 'URL' should not have a hyperlink applied by default (instead, we auto-recognize URLs; see separate test).\n- 'Excluded' fields should not be visible (test of fieldsToExclude).\n- 'Empty' fields should not be visible (test of hideEmptyFields).",
      searchOptions: SEARCH_OPTION_WHOLE_WORD,
      expected: [
        "Standard formatting is supported:\n- All fields called 'Name' or 'Title' (case-insensitive match) should be bold.\n- All fields called 'Name' (case-insensitive match) should be italicized.\n- Fields called 'URL' should not have a hyperlink applied by default (instead, we auto-recognize URLs; see separate test).\n- 'Excluded' fields should not be visible (test of fieldsToExclude).\n- 'Empty' fields should not be visible (test of hideEmptyFields).",
      ],
    },
    // Go here: http://localhost:3000/?d=test_basic_loading&q=see%20records
    // the search phrase is see records -- you will see that 1 record matches (good)
    // turn on whole word matching -- you will see that 0 records match (bad, because the same record should still match, since each whole word is present in the record)
    {
      title: "Search = see records (WW)",
      input: "see records",
      data:
        "Should see three records with three fields each, unformatted, plus 'Expected' field.",
      searchOptions: SEARCH_OPTION_WHOLE_WORD,
      expected: [
        "Should ",
        "see",
        " three ",
        "records",
        " with three fields each, unformatted, plus 'Expected' field.",
      ],
    },
    // Definition tests
    {
      title: `Search = cat (WW): cat contains 1 word: cat`,
      input: "cat",
      data: "cat contains 1 word: cat",
      searchOptions: SEARCH_OPTION_NONE,
      expected: ["", "cat", " contains 1 word: ", "cat", ""],
    },
    {
      title: `Search = cat dog (WW): cat dog contains 2 words: cat dog`,
      input: "cat dog",
      data: "cat dog contains 2 words: cat dog",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat",
        " ",
        "dog",
        " contains 2 words: ",
        "cat",
        " ",
        "dog",
        "",
      ],
    },
    {
      title: `Search = "cat     dog" (WW): cat     dog contains 2 words: cat dog   <-- run of white chars = whitespace`,
      input: "cat     dog",
      data: "contains 2 words: cat dog   <-- run of white chars = whitespace",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "contains 2 words: ",
        "cat",
        " ",
        "dog",
        "   <-- run of white chars = whitespace",
      ],
    },
    {
      title: `Search = "cat dog" contains 1 word: cat dog <-- paired quotes should consider cat dog one single word`,
      input: `"cat dog"`,
      data:
        "cat dog contains 1 word: cat dog <-- paired quotes should consider cat dog one single word",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat dog",
        " ",
        "contains 1 word: ",
        "cat dog",
        " <-- paired quotes should consider ",
        "cat dog",
        " ",
        "one single word",
      ],
    },
    {
      title: `Search = "cat""dog" contains 2 words: cat dog`,
      input: `"cat""dog"`,
      data: "cat dog contains 1 word: cat dog",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat",
        " ",
        "dog ",
        "contains 1 word: ",
        "cat",
        " ",
        "dog",
        "",
      ],
    },
    {
      title: `Search = "cat dog contains 2 words: cat dog  <-- unpaired quote = whitespace`,
      input: `"cat dog`,
      data:
        "cat dog contains 2 words: cat dog  <-- unpaired quote = whitespace",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat",
        " ",
        "dog",
        " contains 2 words: ",
        "cat",
        " ",
        "dog",
        "  <-- unpaired quote = whitespace",
      ],
    },
    {
      title: `Search = cat"dog contains 2 words: cat dog <-- unpaired quote = whitespace`,
      input: `cat"dog`,
      data: "cat dog contains 2 words: cat dog <-- unpaired quote = whitespace",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat",
        " ",
        "dog",
        " contains 2 words: ",
        "cat",
        " ",
        "dog",
        " <-- unpaired quote = whitespace",
      ],
    },
    {
      title: `Search = cat dog" contains 2 words: cat dog  <-- unpaired quote = whitespace`,
      input: `cat dog"`,
      data: "cat dog contains 2 words: cat dog <-- unpaired quote = whitespace",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat",
        " ",
        "dog",
        " contains 2 words: ",
        "cat",
        " ",
        "dog",
        " <-- unpaired quote = whitespace",
      ],
    },
    {
      title: `Search = cat dog"bird contains 3 words: cat dog bird  <-- unpaired quote = whitespace`,
      input: `cat dog"bird`,
      data:
        "cat dog bird contains 3 words: cat dog bird  <-- unpaired quote = whitespace",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat",
        " ",
        "dog",
        " ",
        "bird",
        " contains 3 words: ",
        "cat",
        " ",
        "dog",
        " ",
        "bird",
        "  <-- unpaired quote = whitespace",
      ],
    },
    {
      title: `Search = "cat dog" "bird" contains 2 words: [cat dog] and [bird]`,
      input: `"cat dog" "bird"`,
      data: "cat dog bird contains 2 words: cat dog bird",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat dog",
        " ",
        "bird ",
        "contains 2 words: ",
        "cat dog",
        " ",
        "bird",
        "",
      ],
    },
    {
      title: `Search = "cat dog" bird contains 2 words: [cat dog] and [bird]`,
      input: `"cat dog" bird`,
      data: "cat dog bird contains 2 words: cat dog bird",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat dog",
        " ",
        "bird ",
        "contains 2 words: ",
        "cat dog",
        " ",
        "bird",
        "",
      ],
    },
    {
      title: `Search = cat  contains 1 word: cat  <-- whitespace outside quotes is ignored`,
      input: `cat `,
      data:
        "cat contains 1 word: cat  <-- whitespace outside quotes is ignored",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat",
        " contains 1 word: ",
        "cat",
        "  <-- whitespace outside quotes is ignored",
      ],
    },
    {
      title: `Search = "cat " contains 1 word: cat   <-- whitespace within quotes is not ignored`,
      input: `"cat "`,
      data:
        "cat contains 1 word: cat   <-- whitespace within quotes is not ignored",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat ",
        "contains 1 word: cat   <-- whitespace within quotes is not ignored",
      ],
    },
    {
      title: `Search = " contains 0 words`,
      input: `"`,
      data: "contains 0 words",
      searchOptions: SEARCH_OPTION_NONE,
      expected: "contains 0 words",
    },
    {
      title: `Search = "" contains 0 words`,
      input: `""`,
      data: "contains 0 words",
      searchOptions: SEARCH_OPTION_NONE,
      expected: "contains 0 words",
    },
    {
      title: `Search = ""cat contains 1 word: cat <-- first word "" has zero characters and is ignored`,
      input: `""cat`,
      data:
        '""cat contains 1 word: cat <-- first word "" has zero characters and is ignored',
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat",
        " contains 1 word: ",
        "cat",
        " <-- first word  has zero characters and is ignored",
      ],
    },
    {
      title: `Search = """cat contains 1 word: cat  <-- first word "" has zero characters and is ignored; unpaired quote = whitespace`,
      input: `"""cat`,
      data:
        '"""cat contains 1 word: cat  <-- first word "" has zero characters and is ignored; unpaired quote = whitespace',
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "",
        "cat",
        " contains 1 word: ",
        "cat",
        "  <-- first word  has zero characters and is ignored; unpaired quote = whitespace",
      ],
    },
    {
      title: `Search = "" "" contains 0 words  <-- words with zero characters are ignored`,
      input: `"" ""`,
      data: "contains 0 words",
      searchOptions: SEARCH_OPTION_NONE,
      expected: "contains 0 words",
    },
    {
      title: `Search = contains 0 words  <-- words with zero characters are ignored`,
      input: ``,
      data: "contains 0 words",
      searchOptions: SEARCH_OPTION_NONE,
      expected: "contains 0 words",
    },
    // http://localhost:3000/?d=dxc&q=%22unified%20site%22%20will <-- failure here is obvious, it selects the wrong stuff
    {
      title: `Search = "unified site" will should highlight "unified site" and "will"`,
      input: `"unified site" will`,
      data:
        "LearnOne unified site will contain multiple content types for learning modes, that are micro learning and long-form learning. We want to surface some of these terms we have coined for user feedback, to ensure clarity in messaging and purpose.",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "LearnOne ",
        "unified site",
        " ",
        "",
        "will",
        " ",
        "contain multiple content types for learning modes, that are micro learning and long-form learning. We want to surface some of these terms we have coined for user feedback, to ensure clarity in messaging and purpose.",
      ],
    },
    // http://localhost:3000/?d=dxc&q=%22me%20menu%22%20account <-- ...adding "account" makes it fail with 0 results
    {
      title: `Search = "me menu" account  <-- should highlight [me menu] [account]`,
      input: `"me menu" account`,
      data: `The scope for this research effort is limited to focus on validating the new case management page and navigation to it through the Me Menu (Account menu).`,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "The scope for this research effort is limited to focus on validating the new case management page and navigation to it through the ",
        "Me Menu",
        " (",
        "Account",
        " ",
        "menu).",
      ],
    },
    {
      title: `Search = "me menu" "account"  <-- should highlight [me menu] [account]`,
      input: `"me menu" account`,
      data: `Adding support cases as part of account management in Me Menu tracks with participants expectations, they see it as an improvement. 9 out of 10 participants expect to see it as part of account management in the Me Menu.`,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "Adding support cases as part of ",
        "account ",
        "management in ",
        "Me Menu",
        " ",
        "tracks with participants expectations, they see it as an improvement. 9 out of 10 participants expect to see it as part of ",
        "account",
        " ",
        "management in the ",
        "Me Menu",
        ".",
      ],
    },
    {
      title: `Search = "me menu" track  <-- should highlight [me menu] [track]`,
      input: `"me menu" track`,
      data: `Does the addition of support cases in the Me Menu track with the users expectations?`,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "Does the addition of support cases in the ",
        "Me Menu",
        " ",
        "",
        "track",
        " ",
        "with the users expectations?",
      ],
    },
    {
      title: `Search = "me menu" "track"  <-- should highlight [me menu] [track]`,
      input: `"me menu" "track"`,
      data: `Does the addition of support cases in the Me Menu track with the users expectations?`,
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "Does the addition of support cases in the ",
        "Me Menu",
        " ",
        "",
        "track",
        " ",
        "with the users expectations?",
      ],
    },
    {
      title: `Testing the highlighting of a Excel-style link WITH IGNORE_LINKS where the search term is in the URL, SHOULD ignore 'validation' in the URL`,
      input: "validation",
      data: "=HYPERLINK('https://wiki.autodesk.com/display/edu/Learning+pathway+validation+research';'Lars') Jensen helps keep PRISM running smoothly.",
      searchOptions: SEARCH_OPTION_NONE | SEARCH_OPTION_IGNORE_URL,
      expected: [
        "=HYPERLINK('https://wiki.autodesk.com/display/edu/Learning+pathway+validation+research';'Lars') Jensen helps keep PRISM running smoothly."
      ],
    },
    {
      title: `Testing the highlighting of a Excel-style link WITHOUT IGNORE_LINKS where the search term is in the URL, SHOULD highlight 'validation' in the URL`,
      input: "validation",
      data: "=HYPERLINK('https://wiki.autodesk.com/display/edu/Learning+pathway+validation+research';'Lars') Jensen helps keep PRISM running smoothly.",
      searchOptions: SEARCH_OPTION_NONE,
      expected: [
        "=HYPERLINK('https://wiki.autodesk.com/display/edu/Learning+pathway+",
        "validation",
        "+research';'Lars') Jensen helps keep PRISM running smoothly."
      ],
    }
  ];

  let testCount = 0;
  let passCount = 0;
  tests.forEach((test) => {
    if (test.debug) debugger;

    let result = highlightText(
      // searchMe
      test.data,
      // searchString
      test.input,
      // searchOptions
      test.searchOptions
    );
    // Removes the React components so we can compare strings only
    if (Array.isArray(result)) {
      result = extractPlainTextFromParsedLinks(result);
    }
    const passed = arraysAreEqual(result, test.expected);
    ++testCount;
    if (passed) ++passCount;
    logTestResults(
      passed,
      testName + ": " + test.title,
      test.input,
      test.expected,
      result
    );
  });

return logTestFinish(testName, testCount, passCount);

} // end test_FoundTextHighlighting

//////////////////////////////////////////////////////////////////////////

// unused; causes compile warning
// const cachedDatasetData = [];

// async function getDatasetData(datasetId) {
//   if (cachedDatasetData[datasetId] === undefined) {
//     const fileData = await loadDatasetFile(datasetMetadataFromId(datasetId));
//     cachedDatasetData[datasetId] = fileData[datasetId];
//   }

//   return cachedDatasetData[datasetId];
// } // end getDatasetData

//////////////////////////////////////////////////////////////////////////

// currenty unused; causes compile warning

// function dataTableFromJson(jsonData) {
//   const headers = Object.keys(jsonData[0]);
//   const data = [];
//   jsonData.forEach((_data) => {
//     data.push(Object.values(_data));
//   });

//   const table = {
//     headers: headers,
//     records: data,
//   };

//   return table;
// } // end dataTableFromJson
