import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import ProgressBar from "@hig/progress-bar";
import App from "../App";

// Forge OAuth configuration
// Flow: https://aps.autodesk.com/en/docs/oauth/v2/developers_guide/basics/
const OAUTH_URL = "https://developer.api.autodesk.com/authentication/v2/authorize";
// https://aps.autodesk.com/myapps/9bf4ab23-c3ef-4cc7-9972-8b63b553e36b/
const CLIENT_ID = "SkkJ4zWJkfsEZAbhnsg0nLpzcUa1IGqGYU5EvsTYy8DuoMI9";
const CLIENT_SECRET = "hIyBbu5AD1clLI8CVj1l2Xbc9fOtDmsEb76yWbfvHC9b5A3WdxVCorAplkvQ14g1";
const redirectUrl = `${window.location.origin}${window.location.pathname}`.replace(/\/+$/, '');

const isValidToken = (token) => {
  if (!token) return false;
  try {
    const decoded = jwt_decode(token);
    // decoded.exp is token expiration date in seconds
    if (decoded.exp > Date.now() / 1000) {
      // token is not expired
      return true;
    } else {
      console.error('isValidToken() token expired:', decoded);
      return false;
    }
  } catch (error) {
    console.error('isValidToken() generic error:', error);
    return false;
  }
}

const hasUrlToken = async () => {
  console.log('useEffect::hasUrlToken()');
  const urlParams = new URLSearchParams(window.location.search);
  // Retrieve token from URL Fragment (?code=XXXXXXX)
  const code = urlParams.get("code");
  let valid = false;
  console.log('useEffect::hasUrlToken() code:', code);
  // Get a 3-Legged Token with Authorization Code Grant (https://aps.autodesk.com/en/docs/oauth/v2/tutorials/get-3-legged-token/)
  var urlencoded = new URLSearchParams();
  urlencoded.append("code", code);
  urlencoded.append("client_id", CLIENT_ID);
  urlencoded.append("client_secret", CLIENT_SECRET);
  urlencoded.append("redirect_uri", redirectUrl);
  urlencoded.append("grant_type", "authorization_code");
  console.log('hasUrlToken() urlencoded:', urlencoded);
  await fetch("https://developer.api.autodesk.com/authentication/v2/token", {
    method: "POST",
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      "Accept": "application/json"
    },
    redirect: "follow",
    //credentials: "omit",
    body: urlencoded,
  })
  .then(response => {
    console.log('hasUrlToken() fetch jwt token response', response);
    if (!response.ok) {
      // If we got a 400 it's probably due to an expired code
      console.error('hasUrlToken() fetch jwt token response error:', response);
      return false;
    }
    return response.json();
  })
  .then(
    (data) => {
      if ( data.access_token && data.token_type === 'Bearer' ) {
        const token = data.access_token;
        if (isValidToken(token)) {
          // Store token on browser local storage
          localStorage.setItem("adsk_prism_token", token);
          valid = true;
          //console.log('hasUrlToken() sucessful fetch:', token);
        }
      } else {
        console.error('hasUrlToken() fetch data is invalid:', data);
      }
    },
    // Note: it's important to handle errors here
    // instead of a catch() block so that we don't swallow
    // exceptions from actual bugs in components.
    (error) => {
      console.error('hasUrlToken() fetch error:', error);
    }
  );

  if (valid) {
    return true;
  }
}

const hasLocalToken = () => {
  console.log('useEffect::hasLocalToken()');
  // Retrieve token from browser local storage
  const token = localStorage.getItem("adsk_prism_token");
  return isValidToken(token);
}

const login = () => {
  // Redirect to Forge OAuth endpoint
  const apiUrl = new URL(OAUTH_URL);
  apiUrl.searchParams.append("response_type", "code");
  apiUrl.searchParams.append("scope", "data:read");
  apiUrl.searchParams.append("client_id", CLIENT_ID);
  apiUrl.searchParams.append("redirect_uri", redirectUrl);
  //apiUrl.searchParams.append("state", "authorize");
  window.location.href = apiUrl.href;
  console.log('login::Redirecting to:', apiUrl.href);
};

function ProtectedRoute() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    // try local storage first
    if (hasLocalToken()) {
      console.log('useEffect::hasLocalToken()');
      setIsLoading(false);
    // Try to find a valid token on the URL
    } else if (await hasUrlToken()) {
      // Clear token from URL
      const searchParams = localStorage.getItem("adsk_prism_url_param");
      if (searchParams) {
        history.replace(searchParams);
      } else {
        history.replace("#");
      }
      setIsLoading(false);
    } else {
      login();
    }
  }, []);

  if (isLoading) {
    return <ProgressBar />;
  }

  return <App />;
}

export default ProtectedRoute;
